@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  align-items: center;
  border-radius: var(--cornerradius12);
  gap: 8px;
  padding: 8px 10px;
  background: var(--cst-card-seller-info-background-default);
  box-shadow: inset 0 0 0 2px var(--cst-card-seller-info-outline-default);
  transition: all 0.2s ease;
  @include fixedSize(inherit, 54px);

  &.error {
    background: var(--cst-card-seller-info-background-pressed);
    box-shadow: inset 0 0 0 2px var(--cst-card-seller-info-outline-pressed);
    pointer-events: none;

    .avatar {
      opacity: 0.5;
    }

    .icon-text {
      .text {
        .name {
          color: var(--cst-card-seller-info-title-pressed);
        }
      }
    }
  }

  @include min-428-break {
    border-radius: var(--cornerradius14);
    padding: 10px 12px;
    @include fixedSize(inherit, 74px);
  }

  @include min-1024-break {
    border-radius: var(--cornerradius12);
    padding: 8px 10px;
    @include fixedSize(inherit, 54px);
  }

  @include min-1440-break {
    border-radius: var(--cornerradius14);
    padding: 10px 12px;
    @include fixedSize(inherit, 74px);
  }

  .status-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;

    @include min-428-break {
      gap: 8px;
    }

    @include min-1024-break {
      gap: 6px;
    }

    @include min-1440-break {
      gap: 8px;
    }
  }

  .avatar {
    @include singleFixedSize(36px);

    box-shadow: inset 0 0 0 2px var(--global-purple600);

    &:hover {
      box-shadow: inset 0 0 0 2px var(--global-purple600);
    }

    div[id='avatar-img-container'] {
      @include singleFixedSize(30px);
    }

    @include min-428-break {
      box-shadow: inset 0 0 0 3px var(--global-purple600);
      @include singleFixedSize(48px);

      &:hover {
        box-shadow: inset 0 0 0 3px var(--global-purple600);
      }

      div[id='avatar-img-container'] {
        @include singleFixedSize(40px);
      }
    }

    @include min-1024-break {
      @include singleFixedSize(33px);
      box-shadow: inset 0 0 0 2px var(--global-purple600);

      &:hover {
        box-shadow: inset 0 0 0 2px var(--global-purple600);
      }

      div[id='avatar-img-container'] {
        @include singleFixedSize(27px);
      }
    }

    @include min-1440-break {
      box-shadow: inset 0 0 0 3px var(--global-purple600);
      @include singleFixedSize(48px);

      &:hover {
        box-shadow: inset 0 0 0 3px var(--global-purple600);
      }

      div[id='avatar-img-container'] {
        @include singleFixedSize(40px);
      }
    }
  }

  .icon-text {
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;

    @include min-428-break {
      gap: 12px;
    }

    @include min-1024-break {
      gap: 8px;
    }
    @include min-1440-break {
      gap: 12px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1px;

      @include min-428-break {
        gap: 4px;
      }

      @include min-1024-break {
        gap: 1px;
      }

      @include min-1440-break {
        gap: 4px;
      }

      .name {
        @include typography-s;
        @include weight-semi-bold;
        color: var(--cst-card-player-info-title-default);

        @include min-428-break {
          @include typography-m;
        }

        @include min-1024-break {
          @include typography-s;
        }

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }
}

.navigation-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &.error {
    pointer-events: none;
    opacity: 0.5;
  }

  @include fixedSize(34px, 54px);

  @include min-428-break {
    @include fixedSize(44px, 74px);
  }

  @include min-1024-break {
    @include fixedSize(34px, 54px);
  }

  @include min-1440-break {
    @include fixedSize(44px, 74px);
  }

  &--next {
    .arrow-icon {
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }

  &--prev {
  }

  .arrow-icon {
    @include singleFixedSize(22px);
    color: #6941c6;

    @include min-428-break {
      @include singleFixedSize(22px);
    }

    @include min-1024-break {
      @include singleFixedSize(16px);
    }

    @include min-1440-break {
      @include singleFixedSize(22px);
    }
  }
}

.sellers-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
  justify-content: space-between;
  min-height: max-content;

  @include min-428-break {
    gap: 10px;
  }

  @include min-1024-break {
    gap: 6px;
  }

  @include min-1440-break {
    gap: 10px;
  }
}

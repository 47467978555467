@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.drop-option-container {
  display: flex;
  align-items: center;

  @include fixedSize(100%, 58px);

  @include min-428-break {
    @include fixedSize(100%, 78px);
  }

  @include min-1024-break {
    @include fixedSize(100%, 80px);
  }

  @include min-1440-break {
    @include fixedSize(100%, 100px);
  }
}

.option-item-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  justify-self: left;

  .item {
    z-index: 2;
    @include singleFixedSize(48px);

    @include min-428-break {
      @include singleFixedSize(60px);
    }

    @include min-1024-break {
      @include singleFixedSize(68px);
    }

    @include min-1440-break {
      @include singleFixedSize(80px);
    }
  }

  .item-rarity {
    position: absolute;

    @include singleFixedSize(58px);

    @include min-428-break {
      @include singleFixedSize(78px);
    }

    @include min-1024-break {
      @include singleFixedSize(80px);
    }

    @include min-1440-break {
      @include singleFixedSize(100px);
    }
  }
}

.item-paddings {
  padding: 5.8px 9.28px;

  @include min-428-break {
    padding: 7.8px 12.48px;
  }

  @include min-1024-break {
    padding: 8px 13.33px;
  }

  @include min-1440-break {
    padding: 10px 16.67px;
  }
}

.option-item-info-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2px;

  @include min-1024-break {
    gap: 4px;
  }
  .title {
    @include typography-base;
    @include weight-semi-bold;
    color: var(--cst-cards-item-page-newprice);
    display: block;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    width: 99%;

    @include min-428-break {
      @include typography-m;
    }

    @include min-1024-break {
      @include typography-base;
    }

    @include min-1440-break {
      @include typography-m;
    }
  }

  .age {
    text-transform: capitalize;
    @include typography-xs;
    @include weight-medium;
    color: var(--cst-cards-shoping-item-title);

    @include min-428-break {
      @include typography-s;
    }

    @include min-1440-break {
      @include typography-base;
    }
  }
}

.option-item-properties-container {
  display: flex;
  align-items: center;
  width: max-content;
  background: var(--cst-cards-shoping-item-background-tags);
  border-radius: var(--cornerradius8);
  padding: 8px;
  gap: 6px;

  @include min-428-break {
    gap: 8px;
  }

  @include min-1024-break {
    gap: 10px;
  }

  .category {
    @include singleFixedSize(14px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }

    @include min-1024-break {
      @include singleFixedSize(14px);
    }

    @include min-1440-break {
      @include singleFixedSize(18px);
    }
  }
}

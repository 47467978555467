@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.content {
  display: flex;
  gap: 16px;
  align-items: center;

  .text {
    font-size: 18px;
    line-height: 27px;

    @include min-1440-break {
      font-size: 20px;
      line-height: 30px;
    }
    font-family: Fredoka, sans-serif;
    font-style: normal;
    font-weight: 600;

    color: var(--cst-advertising-text);
  }
}

.advertising {
  padding: 7px 9px;

  @include fixedSize(100%, 48px);

  @include min-1440-break {
    @include fixedSize(100%, 56px);
    padding: 11px 9px;
  }

  span[id='adv-left-illustration'] {
    @include max-744-break {
      display: none;
    }

    @include min-1024-break {
      left: 60px;
      scale: 0.9;
    }

    @include min-1440-break {
      left: 150px;
      scale: 1;
    }
  }

  span[id='adv-right-illustration'] {
    @include max-744-break {
      display: none;
    }

    @include min-1024-break {
      right: 60px;
      scale: 0.9;
    }

    @include min-1440-break {
      right: 150px;
      scale: 1;
    }
  }
}

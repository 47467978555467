@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-payment-method {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: var(--cornerradius12);
  padding: 12px;
  background: var(--cst-cards-payment-method-background-default);
  box-shadow: inset 0 0 0 2px var(--cst-cards-payment-method-outline-default);
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--cst-cards-payment-method-background-hover);
    box-shadow: inset 0 0 0 2px var(--cst-cards-payment-method-outline-hover);
  }

  &.active {
    background: var(--cst-cards-payment-method-background-hover);
    box-shadow: inset 0 0 0 2px var(--cst-cards-payment-method-outline-hover);
  }

  .tag-info {
    position: absolute;
    top: 7px;
    left: 7px;

    @include min-428-break {
      top: 12px;
      left: 12px;
    }
  }
  .payment-method {
    @include fixedSize(64px, 32px);

    @include min-428-break {
      @include fixedSize(90px, 45px);
    }

    @include min-1024-break {
      @include fixedSize(88px, 42px);
    }
  }

  .check-icon {
    position: absolute;
    bottom: 7px;
    right: 7px;
    color: #6941c6;

    @include min-428-break {
      bottom: 12px;
      right: 12px;
    }
    @include singleFixedSize(20px);

    @include min-428-break {
      @include singleFixedSize(24px);
    }

    @include min-1024-break {
      @include singleFixedSize(28px);
    }
  }

  @include fixedSize(100%, 82px);

  @include min-428-break {
    @include fixedSize(100%, 100px);
    border-radius: var(--cornerradius16);
  }

  @include min-1024-break {
    @include fixedSize(172px, 104px);
    border-radius: var(--cornerradius12);
  }
}

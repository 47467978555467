@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: var(--cornerradius10);
  padding: 10px;
  background: var(--cst-card-player-info-background-default);
  transition: all 0.2s ease;
  cursor: pointer;
  @include fixedSize(100%, 53px);

  &:hover,
  &:active {
    background: var(--cst-card-player-info-background-pressed);
    .icon {
      color: var(--global-grey800);
    }
  }

  &:active {
    scale: 0.99;
  }

  .avatar {
    @include singleFixedSize(33px);

    box-shadow: inset 0 0 0 2px var(--global-purple600);

    &:hover {
      box-shadow: inset 0 0 0 2px var(--global-purple600);
    }

    div[id='avatar-img-container'] {
      @include singleFixedSize(27px);
    }

    @include min-428-break {
      box-shadow: inset 0 0 0 3px var(--global-purple600);
      @include singleFixedSize(48px);

      &:hover {
        box-shadow: inset 0 0 0 3px var(--global-purple600);
      }

      div[id='avatar-img-container'] {
        @include singleFixedSize(40px);
      }
    }

    @include min-1024-break {
      @include singleFixedSize(33px);
      box-shadow: inset 0 0 0 2px var(--global-purple600);

      &:hover {
        box-shadow: inset 0 0 0 2px var(--global-purple600);
      }

      div[id='avatar-img-container'] {
        @include singleFixedSize(27px);
      }
    }

    @include min-1440-break {
      box-shadow: inset 0 0 0 3px var(--global-purple600);
      @include singleFixedSize(48px);

      &:hover {
        box-shadow: inset 0 0 0 3px var(--global-purple600);
      }

      div[id='avatar-img-container'] {
        @include singleFixedSize(40px);
      }
    }
  }

  .icon-text {
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;

    @include min-428-break {
      gap: 12px;
    }

    @include min-1024-break {
      gap: 8px;
    }
    @include min-1440-break {
      gap: 12px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include min-428-break {
        gap: 4px;
      }

      @include min-1024-break {
        gap: 0px;
      }

      @include min-1440-break {
        gap: 4px;
      }

      .name {
        @include typography-s;
        @include weight-semi-bold;
        color: var(--cst-card-player-info-title-default);

        @include min-428-break {
          @include typography-m;
        }

        @include min-1024-break {
          @include typography-s;
        }

        @include min-1440-break {
          @include typography-m;
        }
      }

      .nickname {
        @include typography-xs;
        @include weight-medium;
        color: var(--cst-card-player-info-subtitle-default);

        @include min-428-break {
          @include typography-s;
        }

        @include min-1024-break {
          @include typography-xs;
        }

        @include min-1440-break {
          @include typography-s;
        }
      }
    }
  }

  @include min-428-break {
    border-radius: var(--cornerradius12);
    padding: 12px;
    @include fixedSize(100%, 72px);
  }

  @include min-1024-break {
    border-radius: var(--cornerradius10);
    padding: 10px;
    @include fixedSize(100%, 53px);
  }

  @include min-1440-break {
    border-radius: var(--cornerradius12);
    padding: 12px;
    @include fixedSize(100%, 72px);
  }
}

.icon {
  @include singleFixedSize(18px);

  color: var(--global-grey700);
  transition: all 0.2s ease;

  @include min-428-break {
    @include singleFixedSize(24px);
  }

  @include min-1024-break {
    @include singleFixedSize(18px);
  }

  @include min-1440-break {
    @include singleFixedSize(24px);
  }
}

@use '@fixed-size' as *;
@use '@content' as *;
@use '@queries' as *;

.item {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: var(--cornerradius10);
  box-shadow: inset 0 0 0 2px var(--alias-outline);
  place-self: center;
  overflow: hidden;
  padding-top: 5px;
  @include fixedSize(100%, 72px);

  aspect-ratio: 1/1;

  @include min-428-break {
    @include fixedSize(100%, 72px);
  }

  @include min-1024-break {
    @include fixedSize(100%, 72px);
  }

  @include min-1440-break {
    @include fixedSize(100%, 72px);
  }

  .center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    @include fixedSize(100%, 50px);
  }

  .item-variant {
    z-index: 1;
    pointer-events: none;

    @include singleFixedSize(42px);
  }

  .background {
    position: absolute;
    z-index: 0;
    //padding: 0 5px 5px 5px ;

    @include singleFixedSize(100%); //50px
  }

  .item-tags {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: 4px;
    align-items: center;
    width: max-content;
    -moz-border-radius-topleft: var(--cornerradius8);
    border-top-left-radius: var(--cornerradius8);
    padding: 4px;
    background: var(--cst-cards-live-feed-backgroundtags-default);

    .tag {
      @include singleFixedSize(10px);
    }
  }
}

.wrapper {
  display: grid;
  width: 100%;
  border-radius: var(--cornerradius8);
  box-shadow: inset 0 0 0 2px var(--global-grey400);
  padding: 6px 9px;
  gap: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 146px;
  @include hideScrollbar;

  grid-template-columns: repeat(4, minmax(60px, 1fr));

  @include min-428-break {
    gap: 14px;
    padding: 12px;

    max-height: 182px;
    grid-template-columns: repeat(5, minmax(60px, 1fr));
  }

  @include min-744-break {
    border-radius: var(--cornerradius10);

    max-height: 182px;
  }

  @include min-1024-break {
    padding: 10px;
    gap: 12px;

    grid-template-columns: repeat(4, minmax(60px, 1fr));
    max-height: 156px;
  }

  @include min-1440-break {
    padding: 8px;
    gap: 10px;
    justify-content: space-between;
    max-height: 170px;
    grid-template-columns: repeat(5, minmax(60px, 1fr));
  }
}
